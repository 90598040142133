import { AppConfig } from "../config";
import { Link, User, LinkType } from "../models/link";

export function createLinkUrl(redirectUrl: string, linkUrl: string, linkId: string) {
    return `${redirectUrl}redirect?lid=${linkId}&url=${linkUrl}`;
}

export function updateLinkUrl(link: Link) {
    link.url = createLinkUrl(AppConfig.DOMAIN, link.url, link.id);
    return link;
}

export function updateLinkValues(link: Link) {
    link.imageUrl = link.imageUrl || `https://www.google.com/s2/favicons?sz=64&domain=${link.domain}`;
    link.name = link.name || link.resolvedUrl || link.url;
    link.url = createLinkUrl(AppConfig.DOMAIN, link.url, link.id);
    return link;
}

export function sortLinks(a: Link, b: Link) {
    // if $b link occurs less often OR $b is a sponsored link that it should be ranked higher
    if ((a.type != LinkType.Sponsored && b.type == LinkType.Sponsored) || (a.videoCount > b.videoCount)) {
        return -1;
    }
    if ((a.type == LinkType.Sponsored && b.type != LinkType.Sponsored) || (a.videoCount < b.videoCount)) {
        return 1;
    }
    
    return 0;
}

export function hasChannelPermissions(user: User, channelId: string) {
    return !!(user.isAdmin || (user.ownedChannels && user.ownedChannels.find((c) => c.id === channelId)));
}

export function createFetchURL(originalUrl: string, filters: any) {
    Object.keys(filters).forEach(key => filters[key] === undefined && delete filters[key]);
    let url = new URL(originalUrl);
    // @ts-ignore
    url.search = new URLSearchParams(filters);
    return url;
}

export interface LinkRes {
    channelId: string
    channelName?: string
    channelPictureUrl?: string
    id: string
    url: string
    resolvedUrl?: string
    type: string
    name: string
    domain: string
    discount: string
    code: string
    imageUrl: string
    isSponsored: Boolean
}

export function convertResToLinks(res: LinkRes) : Link {
    return {
        id: res.id,
        url: createLinkUrl(AppConfig.DOMAIN, res.url, res.id),
        resolvedUrl: res.resolvedUrl,
        type: res.type,
        imageUrl: res.imageUrl || `https://www.google.com/s2/favicons?sz=64&domain=${res.domain}`,
        name: res.name || res.resolvedUrl || res.url,
        domain: res.domain,
        channel: {
            id: res.channelId,
            name: res.channelName || '',
            imageUrl: res.channelPictureUrl || ''
        },
        discount: {
            amount: res.discount,
            code: res.code
        },
        isSponsored: res.isSponsored
    } as Link;
}