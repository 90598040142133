<script setup lang="ts">
import TheUserMenu from "./TheUserMenu.vue";
import TheNavDrawer from "./TheNavDrawer.vue";
import { mdiMagnify } from '@mdi/js';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '../store/user';

const userStore = useUserStore();

const showDrawer = ref(false);
const { user } = storeToRefs(userStore);

</script>

<template>
<v-app-bar :elevation="0">
  <template v-slot:prepend>
    <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer"></v-app-bar-nav-icon>
  </template>

  <v-app-bar-title class="site-title"><router-link :to="`/`" class="name">Clicjar</router-link></v-app-bar-title>

  <template v-slot:append>
    <v-btn :icon="mdiMagnify"></v-btn>
    <TheUserMenu/>
  </template>
</v-app-bar>
<!--
<header class="header">
    <router-link :to="`/`">
        <div class="brand">
            <h1 class="site-title">Clicjar</h1>
        </div>
    </router-link>
    <div class="user"></div>
    <TheUserMenu/>
</header>
-->
<TheNavDrawer :show-drawer="showDrawer" :user="user"/>
</template>

<style scoped>
.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}
.brand {
    display: flex;
    align-items: center;
    gap: 10px;
}
.logo {
    width: 40px;
    height: 40px;
}
#app .site-title {
    text-align: left;
}
#app .site-title .name {
    font-size: 38px;
    font-family: "Sacramento", cursive;
    font-weight: 400;
    font-style: normal;
    color: #333;
    text-align: left;
    line-height: 57px;
}
.user {
    display: flex;
    align-items: center;
}
</style>