//// <reference types="google.accounts, jsonwebtoken" />

import { defineStore } from "pinia";
import { ref } from "vue";
import { User } from "../models/link";
import { AppConfig}  from '../config';
import { useLinkStore } from './links';
import { useListsStore } from './lists';
import { useChannelStore } from './channels';

export const useUserStore = defineStore('user', () => {
  let user = ref({} as User);

  async function getGoogleUser(token: string) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`);
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    const jsonData = await response.json();
    user.value = {
      id: '',
      imageUrl: jsonData.picture,
      name: jsonData.name,
      token: jsonData
    };
  }

  async function login(tokenID: string) {
    const reqData = JSON.stringify({id: tokenID});

    const response = await fetch(AppConfig.DOMAIN + `login`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: reqData
    });
    if (!response.ok) {
      if (response.status == 401) {
        throw new Error('401');
      } else {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
    }
    const jsonData = await response.json();
    
    user.value.id = jsonData.id;
    user.value.isAdmin = jsonData.role == 'admin'
    user.value.ownedChannels = jsonData.ownedChannels;
  }

  async function logout() {
    if (!user.value.id) return;

    // call logout to remove session data and cookie.
    const response = await fetch(AppConfig.DOMAIN + `logout`, {
      method: 'POST',
      credentials: 'include'
    });
    if (!response.ok) {
      if (response.status == 401) {
        throw new Error('401');
      } else {
        throw new Error(`${response.status}: ${response.statusText}`);
      }
    }
    google.accounts.id.revoke(user.value.token!.sub!, done => {
      console.log(done.error);
    });
    google.accounts.id.disableAutoSelect();
    // remove data from other stores.
    _reset();
  }

  function _reset() {
    user.value.id = '';
    user.value.isAdmin = false;
    useLinkStore().reset();
    useListsStore().reset();
    useChannelStore().reset();
  }
  
  return { user,  getGoogleUser, login, logout}
});