import { JwtPayload } from 'jsonwebtoken';

export enum LinkType {
    Social = "social",
    Sponsored = "sponsored",
    Affiliate = "support_platform",
    Product = "product",
    Channel = "channel",
    Other = "other",
}

export interface Channel {
    id: string
    name: string
    imageUrl: string
    youtubeId?: string
    youtubeCustomUrl?: string
    quickLinks?: Link[]
}

export interface Video {
    id: string
    title: string
    thumbnailUrl: string
    publishedAt?: string
    ytId?: string
    channel?: Channel
    links?: Link[]
}

export interface Discount {
    amount: string
    code: string
}

export interface Link {
    id: string
    url: string
    resolvedUrl?: string
    type: string
    name: string
    domain: string
    channel: Channel
    discount: Discount
    videoCount: Number
    video?: Video
    isQuickLink?: Boolean
    imageUrl: string
    isSponsored: Boolean
}

export interface RecentVideo {
    video: Video
    links: Link[]
}

export interface List {
    id: string
    name: string
    imageUrl?: string
    links?: Link[]
}

export interface User {
    id: string
    name: string
    imageUrl: string
    token?: JwtPayload
    ownedChannels?: Channel[]
    isAdmin?: Boolean
}